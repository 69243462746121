<template>
  <div>
    <div
      :style="getElementStyle"
      v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
    >
      <!-- {{data.properties}} -->
      <!-- {{data.properties}} -->

      <el-row
        align="middle"
        v-if="
          !data.properties.hideLabel && data.properties.filed_content != 'Hide'
        "
      >
        <el-col
          :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
        >
          <span>
            <label
              for="text"
              v-if="hasLabel"
              :style="getLabelStyles"
              :class="{ 'highlight-border': highlight }"
            >
              {{ label || data.label }}
            </label>
          </span>
          <span
            v-if="data.validations.required && hasLabel"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span style="margin-right: 5px">
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
      </el-row>
      <el-row
        :span="showLabel && !isDefalutPos ? 24 : data.description ? 20 : 24"
      >
        <template v-if="!isView">
          <div class="row">
            <AudioRecorder
              v-if="showRecorder"
              :upload-url="this.uploadUrl"
              :filename="data.label"
              format="wav"
              :attempts="3"
              :time="2"
              :headers="headers"
              :successful-upload="uploadsuccessful"
              :failed-upload="callback"
              :bit-rate="192"
              :src="form[data.key]"
            />
          </div>
        </template>
      </el-row>

      <el-row align="middle">
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
          <template v-if="isView && form[data.key]">
            <AudioPlayer :src="form[data.key]" :view="isView" />
          </template>
          <template v-else>-</template>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
import AudioPlayer from "../AudioTool/components/player.vue";
import AudioRecorder from "../AudioTool/components/recorder.vue";
import { bus } from "../../../main";
export default {
  name: "templates-formComponentsExecute-StarRatingExecute",
  components: {
    AudioPlayer,
    AudioRecorder,
  },
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "checkIsDisabled",
    "profilePage",
    "highlight",
    "fromEntityViews"
  ],
  mounted() {
    this.loading = true;
    //if (this.data.default_value && !this.form[this.data.key]) {
    //  this.$set(this.form, this.data.key, this.data.default_value);
    //}
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
    }
    this.loading = false;
    bus.$on("store-data", (data) => {
      this.form[this.data.key] = data;
    });
    bus.$on("delete-audio", () => {
      this.form[this.data.key] = null;
    });
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      return this.data.styles &&
        this.data.styles.label_color &&
        !this.fromRepeatable
        ? `height:${this.data.height - 30}px; font-weight: 400; width: ${
            this.data.width
          }px;`
        : "height: 70px";
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      inputValue: "",
      maskedValue: "",
      inputValue2: "",
      isDisabled: false,
      mp3: "/demo/example.mp3",
      showRecorder: true,
      headers: {
        "X-Custom-Header": "some data",
      },
      uploadUrl: `${process.env.VUE_APP_DEV_API_URL}/upload/audio-upload-s3`,
    };
  },

  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "SINGLE_LINE_TEXT") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    callback() {},
    toggle() {
      this.showRecorder = !this.showRecorder;
    },
    uploadsuccessful() {
      this.$message({
        message: "Audio Fileuploaded successfully!",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
